import React from "react"

import Seo from "../components/seo"
import Prose from "../components/utility/prose"

const NotFoundPage = () => (
  <>
    <Prose>
    <Seo title="404: Not found" />
    <h1>Page Not Found</h1>
    <p>You just hit a route that doesn&#39;t exist. :(</p>
    </Prose>
  </>
)

export default NotFoundPage